import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,3,4,5];

export const dictionary = {
		"/(appLayout)": [8,[2]],
		"/(appLayout)/account": [11,[2,3]],
		"/(appLayout)/account/api-access": [12,[2,3]],
		"/(appLayout)/account/change-email": [13,[2,3]],
		"/(appLayout)/account/change-password": [14,[2,3]],
		"/(appLayout)/account/company": [15,[2,3]],
		"/(appLayout)/account/notifications": [16,[2,3]],
		"/(appLayout)/account/payments": [17,[2,3]],
		"/(appLayout)/account/profile": [18,[2,3]],
		"/(blankLayout)/account/setup": [~30,[6]],
		"/(blankLayout)/activate-account/[code]": [~31,[6]],
		"/(appLayout)/activate-email/[token]": [~19,[2]],
		"/(narrowLayout)/edit-offer/[[id]]/checkout": [~40,[7]],
		"/(blankLayout)/edit-offer/[id]/payment/[saleQuoteId]": [32,[6]],
		"/(narrowLayout)/edit-offer/[[id]]": [39,[7]],
		"/(blankLayout)/login": [~33,[6]],
		"/(appLayout)/messages": [20,[2]],
		"/(appLayout)/my-offers": [21,[2]],
		"/(narrowLayout)/new-offer": [41,[7]],
		"/(appLayout)/observed": [22,[2,4]],
		"/(appLayout)/observed/searches": [23,[2,4]],
		"/(appLayout)/oferta/[offerSurl]-O[offerId]": [24,[2]],
		"/(blankLayout)/pricing": [34,[6]],
		"/(appLayout)/(cms-strapi)/p/[...slug]": [~9,[2]],
		"/(blankLayout)/register": [~35,[6]],
		"/(blankLayout)/reset-password": [~36,[6]],
		"/(blankLayout)/reset-password/[code]": [~37,[6]],
		"/(appLayout)/super": [25,[2,5]],
		"/(appLayout)/super/offers": [26,[2,5]],
		"/(appLayout)/super/pricing": [27,[2,5]],
		"/(blankLayout)/super/ui": [38,[6]],
		"/(appLayout)/super/users": [~28,[2,5]],
		"/(appLayout)/super/workers": [29,[2,5]],
		"/(narrowLayout)/thank-you": [42,[7]],
		"/(appLayout)/[...listingUrl]": [10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';