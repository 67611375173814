import * as Sentry from '@sentry/sveltekit';

import {
	PUBLIC_APP_STAGE,
	PUBLIC_FEATURE_IS_ENABLED_SENTRY,
	PUBLIC_SENTRY_AUTH_TOKEN,
} from '$env/static/public';

Sentry.init({
	dsn: PUBLIC_SENTRY_AUTH_TOKEN,
	tracesSampleRate: 0.1,
	environment: PUBLIC_APP_STAGE?.includes('prod') ? 'production' : PUBLIC_APP_STAGE ?? 'undefined',
	enabled: PUBLIC_FEATURE_IS_ENABLED_SENTRY === 'true',

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.01,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		new Sentry.Replay(),
		new Sentry.BrowserTracing({
			tracePropagationTargets: [],
		}),
	],
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const myErrorHandler = ({ error, event }: any) => {
	// This console log is intentional.
	console.error(error);
	let exception = error.error || error.message || error.originalError || error;
	if (typeof exception === 'string') {
		exception = {
			message: exception,
		};
	}
	Sentry.captureException(exception, (captureContext) => {
		captureContext.setTransactionName(`Client side error`);
		captureContext.setExtra('event', event);
		return captureContext;
	});
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
